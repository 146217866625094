import { Link } from "gatsby"
import React from "react"
import styles from './header.module.scss'

const Header = () => (
  <nav className={styles.container}>
    <Link to="/" className="">Yangli.io</Link>
    <ul className={styles.list}>
      <li><Link to="/blog" className={styles.navLink}>Blog</Link></li>
      <li><Link to="/blog" className={styles.navLink}>Blockchain</Link></li>
      <li><Link to="/blog" className={styles.navLink}>Software Development</Link></li>
      <li><Link to="/resume" className={styles.navLink}>Resume</Link></li>
    </ul>
  </nav>
)

export default Header
